const queryKeys = {
	'TICKET_INFO': 'ticket-info',
	'RESULT_DROPDOWN_LIST_NEW': 'result-dropdown-list-new',
	'RESULT_OUTRIGHT_DROPDOWN_LIST_NEW': 'result-outright-dropdown-list-new',
	'RESULT_INDEX': 'result-index',
	'RESULT_OUTRIGHT_INDEX': 'result-outright-index',
	'RESULT_TABLET_SOCCER_DETAIL': 'result-tablet-soccer-detail',
	'RESULT_TABLET_ESPORT_DETAIL': 'result-tablet-esport-detail',
	'RESULT_URL': 'result-url',
	'RESULT_AUTHORIZATION_SPORTS_LOTTERY_RESULT': 'result-authorization-sports-lottery-result',
	'RESULT_AUTHORIZATION_INDO_4D_RESULT': 'result-authorization-indo-4d-result',
	'RESULT_AUTHORIZATION_VIRTUAL_GAME_RESULT': 'result-authorization-virtual-game-result',
	'RESULT_AUTHORIZATION_BULL_FIGHTING_RESULT': 'result-authorization-bull-fighting-result',
	'RESULT_N7_LOTTO_RESULT': 'result-n7-lotto-result',
	'RESULT_GAMES_RESULT': 'result-games-result',
	'TICKET_BET': 'ticket-bet',
	'TICKET_STATUS': 'ticket-status',
	'PARLAY_STATUS': 'parlay-status',
	'MENU_DATA': 'menu-data',
	'SAVE_SPORT_SORT': 'save-sport-sort',
	'STREAMING_INDEX': 'streaming-index',
	'STREAMING_CLOUD_ROCK': 'streaming-cloud-rock',
	'STREAMING_IN_HOUSE': 'streaming-in-house',
	'STREAMING_IN_HOUSE_WITH_SECRET': 'streaming-in-house-with-secret',
	'STREAMING_GLIVE': 'streaming-glive',
	'STREAMING_STREAMER21': 'streaming-streamer21',
	'STREAMING_MCDN': 'streaming-MCDN',
	'STREAMING_GV_KEY': 'streaming-gv_key',
	'ACCOUNT_SETTINGS': 'account-settings',
	'SAVE_ACCOUNT_SETTINGS': 'save-account-settings',
	'USER_REWADR_INFO': 'user-reward-info',
	'UNSETTLED_CATEGORY_INFO': 'unsettled-category-info',
	'UNSETTLED_BET_LIST_INFO': 'unsettled-bet-list-info',
	'UNSETTLED_STATUS_COUNT_INFO': 'unsettled-status-count-info',
	'SETTLED_CATEGORY_INFO': 'settled-category-info',
	'SETTLED_ALL_BETS_INFO': 'settled-all-bets-info',
	'SETTLED_BET_LIST_INFO': 'settled-bet-list-info',
	'RESULT_POPUP_INFO': 'result-popup-info',
	'VGAMING_RESULT_POPUP_INFO': 'vgaming-result-popup-info',
	'MESSAGES_DATA': 'messages-data',
	'MESSAGE_TOP': 'messages-top',
	'MESSAGES_CHECKDATA': 'messages=check-data',
	'MIX_PARLAY_DETAIL_INFO': 'mix-parlay-detail-info',
	'SITE_CONFIGS': 'site-configs',
	'SWITCH_MODE': 'switch-mode',
	'SKIN_MODE_URL': 'skin-mode-url',
	'MY_FAVOURITE_INFO': 'my-favourite-info',
	'MY_FAVOURITE_STATUS': 'my-favourite-status',
	'SEARCH_AUTOCOMPLETE': 'search-autocomplete',
	'SEARCH_HISTORY': 'search-history',
	'UPDATE_BALANCE': 'update-balance',
	'CASH_OUT_STATUS_PRICE': 'cash-out-status-price',
	'USE_TOKEN_TO_LOGIN': 'use-token-to-login',
	'CASH_OUT_HISTORY_DERAIL': 'cash-out-hietory-detail',
	'GET_SPORT_PIN_SORT': 'get-sport-pin-sort',
	'UM_MESSAGE': 'um-message',
	'GET_MARKET_BY_LEAGUE_MATCH': 'get-market-by-league-match',
	'REWARD_SYSTEM_INFO': 'reward-system-info',
	'REWARD_SYSTEM_LABELS': 'reward-system-labels',
	'LIMIT_MESSAGE': 'limit-message',
	'SPREAD_SETTING': 'spread-setting',
	'PLAYER_SURVEY_IS_SUBMITTED': 'player-survey-is-submitted',
	'LEAVE_SURVEY_IS_SUBMITTED': 'leave-survey-is-submitted',
	'FETCH_CHATROOM_TOKEN': 'fetch-chatroom-token',
	'FETCH_CHATROOM_URL': 'fetch-chatroom-url',
	'FETCH_PLUGIN_URL': 'fetch-plugin-url',
	'GET_SPORT_SORT_JSON': 'get-sport-sort-json',
	'SHOW_ALL_ODDS_FOR_MULTI_SPORT': 'show-all-odds-for-multi-sport',
	'SITE_CONFIGS_WITH_SESSION': 'site-configs-with-session',
	'GET_ESPORT_STREAMING': 'get-esport-streaming',
	'GET_STREAMING_SCHEDULE': 'get-streaming-schedule',
	'MASTER_RANKING': 'master-ranking',
	'HOT_MATCH': 'hot-match',
	'HOT_BETTING': 'hot-betting',
	'GET_NICK_NAME': 'get-nick-name',
	'SAVE_NICK_NAME': 'save-nick-name',
	'Tournament_Group': 'tournament_group',
	'Tournament_Point': 'tournament_point',
	'Tournament_Knockout': 'tournament_knockout',
	'Tournament_Country': 'tournament_country',
	'GET_THIRD_PARTY_TOKEN': 'get-third-party-token',
	'Get_Esport_Streaming': 'Get_Esport_Streaming',
	'GET_BANNER_INFO': 'get-banner-info',
	'CORRECT_SCORE_JACKPOT': 'correct-score-jackpot',
	'CORRECT_SCORE_JACKPOT_PRIZE': 'correct-score-jackpot-prize',
	'CORRECT_SCORE_JACKPOT_BYMATCH': 'correct-score-jackpot-bymatch',
	'CORRECT_SCORE_JACKPOT_ENABLE_LIST': 'correct-score-jackpot-enable-list',
	'CORRECT_SCORE_JACKPOT_DRAW_SETTING': 'correct-score-jackpot-draw-setting',
	'SMP_URL': 'smp-url',
	'EXCHANGE_BALANCE': 'exchange_balance',
	'EXCHANGE_RETRIEVE': 'exchange_retrieve',
	'EXCHANGE_TRANSFER_IN': 'exchange_transfer_in',
	'VIRTUAL_SOCCER_WIDGET': 'virtual-soccer-widget',
	'WHO_AM_I': 'who_am_i',
} as const;

export default queryKeys;

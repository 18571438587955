import { ProvidedMarket, ProvidedMatchCount, EventMode, EventName } from '@/types/menu-setting';
import { matchEquals, MaybeMatch } from '@nf/utils-common/fpw/monadic';

type MakeSingleMatchPathProps = {
    gameId: number;
    maT: string;
    leagueId: number;
    matchId: number;
    eventMode?: EventMode;
    eventName?:EventName;
}
// 若由event路徑進入singleMatch使用此方法並傳入eventMode and eventName 
const makeSingleMatchPath = ({ gameId, maT, leagueId, matchId, eventMode, eventName }: MakeSingleMatchPathProps) => 
	MaybeMatch(eventMode && eventName && eventMode !== 'M0')
		.fold(
			() => matchEquals(maT)
				.on('l', () => `/live/${gameId}/${leagueId}/${matchId}`)
				.otherwise(() => `/sports/${gameId}/${maT}/${leagueId}/${matchId}`),
			() => `/world/${eventName}/${gameId}/${maT}/${leagueId}/${matchId}`
		)

const makeMenuNodeResourceKey = (enableFilterSaba : boolean | undefined, menuMode: string | undefined) => {
	if (enableFilterSaba && (menuMode === '27' || menuMode === '28' || menuMode === '29')) {
		return `VirtualMenu${menuMode}`;
	}
	return `Menu${menuMode}`;
}

const findHighestPriorityMarket = (data: ProvidedMatchCount) => {
	const marketPriority: Array<ProvidedMarket> = ['L', 'WO', 'T', 'E', 'O'];
	const highestPriorityMarket = marketPriority.filter(market => data[market] > 0);
	return highestPriorityMarket.length > 0 ? highestPriorityMarket[0] : 'L';
};

export const sportsbookService = {
	makeSingleMatchPath,
	makeMenuNodeResourceKey,
	findHighestPriorityMarket
};
export interface LanguageData {
	Name: string;
	IsoCode: string;
	ShortCode: string;
}

export type ColorSchemeData = Array<{
	siteTheme: string;
	[key: string]: string;
}>;

export interface CashOutSportList {
	[key: string]: string[];
}

export interface VirtualStreamingLeagueGroupMap {
	[key: string]: string[];
}

export interface IntlResourceData {
	General: Record<string, string>;
	BetType: Record<string, string>;
	Choice: Record<string, string>;
}

export enum SwitchFromEnum {
	Default = -1,
	Mobile = 0,
	Desktop = 1
}

export interface DeepLinkSiteInfo {
	homeUrl: string;
	depositUrl: string;
	extendSessionUrl: string;
	loginUrl: string;
	signUpUrl: string;
	game: string;
	gameId: string;
	oddsType: number;
	skinColor: string;
	skinMode: number;
	forceDarkMode: boolean;
	tsId: string;
	switchFrom: SwitchFromEnum;
	isApp: boolean;
	themePreview: string;
	templateId: string;
	dptoken: string;
	switchFromUrl: string;
	clearCount: number;
}

export interface ApiDomain {
	ctcdDomain: string;
}

export interface SiteConfigsSettingsData {
	BookedBetLeagueGroup: Array<number>;
	RnRUrl: string;
	SupportLanguages: LanguageData[];
	SabaLeagueGroups: number[];
	LoginCheckInTime: number;
	WebSkinTypeAllow: string;
	EnableSingleWallet: boolean;
	OddsTypeList?: string;
	OddsTypeDefault?: string;
	ExpandedMatchCount: string;
	EnableCurrencyDisplay: boolean;
	EnableFilterSaba: boolean;
	NoSupportBetType: number[];
	CanSeeDecimalWidgets: boolean;
	DecimalWidgetsUrl: string;
	EnableStaticInfo: boolean;
	WeverdatStreamingUrl: string;
	KabaddiVStreamingUrl: string;
	EnableCurrencySymbolDisplay: boolean;
	EnableAcceptBetterOdds: boolean;
	EnableAISportSort: boolean;
	CanSeeGLiverStreaming: boolean;
	CheckStreamingList: boolean;
	StreamingSrc: number[];
	ImageServerUrl: string;
	EnableImageServer: boolean;
	SiteDefaultLanguage: LanguageData;
	EnableGalaxyTutorial: boolean;
	EnableGalaxyCustomerSurvey: boolean;
	EnableGalaxyLeaveSurvey: boolean;
	EnableHistorySearch: boolean;
	EnableFps: boolean;
	EnableKafka: string;
	EnableApiBackend: boolean;
	EnableOddsServerApiBackend: boolean;
	EnableMyFavorite: boolean;
	FpsJsUrl: string;
	GalaxyGAId: string;
	GetMessageID: string;
	VirtualStreamingLeagueGroupMap: VirtualStreamingLeagueGroupMap;
	FilterStreamingSportList: string;
	FilterStreamingLeagueList: string;
	ExclusiveStreamerType: string;
	EnableDefaultOpenChatroom: boolean;
	EnableChatroom: boolean;
	DiscoverChatroomApiUrl: string;
	SiteName: string;
	GalaxyLogoType: number;
	EnableOpenStatisticInfo: boolean;
	CanSeeAdvantPlayVendorLobby: boolean;
	isShowSoccer5: boolean;
	isShowParlay5: boolean;
	CanSeeSportsLottery: boolean;
	//WebSkinTypeDefault:number;
	EnablePromotion: number;
	EnablePopularBetting: boolean;
	EnableTournamentGroup: boolean;
	EnableTournamentCountry: boolean;
	EnableTournamentKnockout: boolean;
	ApiBackendUrl: string;
	EnableStreamerSchedule: boolean;
	/**
	 * using for exchange
	 */
	SealComponentAPIUrlForClient: string;
	/**
	 * using for casino royale
	 */
	SealComponentApiUrl: string;
	SingleWalletAdapterApi: string;
	EnableLicLobby: boolean;
	SabaSportsUrl: Record<string, string>;
	PopularBettingCount: number;
	EnableBookedDesc: boolean;
	/** 
	 * Used in user in-depth interviews
	*/
	EnableCustomerDeppConversation: boolean;
	EnableTournament: boolean;
	CanSeeCasinoRoyale: boolean;
	EnableSingleMatchJackpot: boolean;
	EnableInHouseUrlApiBackend : boolean;
	EnableDesktopOrMobileToOnPremise: boolean;
	EnableSabaScore: boolean;
	EnableMarsRevisionNotice: boolean;
	HotMatchCount: number;
	EnableStreamingPlugin: boolean;
	ShowPromotionChatRoom: number[];
	EnableSabaCoinBet: boolean;
	EnableSabaCoinFlow: boolean;
	EnableNewSabaCoinFlow: boolean;
	EnableCoinRewardLevel: boolean;
	EnableSabaCoinStatement: boolean;
	EnableExchangeDeeplink: boolean;
	EnableSabaEuro: boolean;
	ReservationMaxCount: number;
	SabaEuroCupTournamentId: number;
	EnableOldStatementAlert:boolean;
	EnableNextAnalyse: boolean;
	BehaviorCatcherEntry: string;
	CanSeeVSTournament: boolean;
	EnablePinGoalMenuSports: number[];
	EnableFrontendRecordLog?: boolean;
	CanSeeEdgeGaming: boolean;
	HideEndingGameCust5: boolean;
	FirstPageOrders: Array<number>;
}

export interface OneAuthenticationInfo {
	OneAccessToken?: string;
	OneRefreshToken?: string;
}

export interface OddsServerSettingData {
	OddsServerUrl: string;
	OddsServerToken: string;
	OneAuthenticationInfo?: {
		AccessToken?: string;
		RefreshToken?: string;
	};
	ApiBackendUrlInfo?: {
		Url?: string;
		IsShouldClearDomain?: boolean;
	};
}

export interface ExtendOddsTokenData {
	/** Access Token */
	at: string;
	/** Refresh Token */
	rt: string;
	/** Old Token */
	ot: string;
};

export interface IpBlockData {
	SiteId: string;
	RefNo: string;
	Forward: string;
	CountryShort: string;
}

export interface SiteConfigsSettingsWithSessionData {
	WebSkinTypeAllow: string;
	WebSkinTypeDefault: number;
	SwitchFrom: number;
	SkinMode: number;
	EnableExchangeForBefore: boolean;
	MainDomainOnPremise: string;
}

export type AppEnvironment = 'SIT' | 'UAT' | 'PROD';
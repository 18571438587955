import { useEffect } from 'react';
import { useQuery } from '@tanstack/react-query';

import { ApiQueryBasePromise, ApiQueryPromise, ViewEnum } from '@/types';
import { getResponseJwtHeader } from '@/utils/web';
import { cloudUrlParser } from '@/utils/aws-domain-util';
import { fetchComposer } from '@nf/utils-common/compose-fetch';
import queryKeys from '@/constants/query-keys';
import { getFormData } from '@nf/utils-common/form';
import { useSiteStore } from '@/store/site';
import { getAuthActions, getAuthToken, useAuthActions } from '@/store/auth';
import { useAccountStatus } from './account';
import { ApiSuccessResponse } from '@nf/types/api';
import { UserSetting } from '@nf/types/account';

export const stakeSettingMaxLength = 8;
export const stakeSettingDefaultValue = (view: ViewEnum) => view === ViewEnum.galaxy ? 1 : 0;

// 取得user setting 資料
export interface DropdownItems {
	key: string;
	value: string;
}
interface LangInfo {
	ServerName: string;
	LanID: string;
	LanID2: string;
	LanVaue: string;
}
interface OddsTypeList {
	Id: number;
	name: string;
}
interface OddsSortList {
	Value: string;
	Text: string;
}
interface DisplayInfo {
	canSeeHomePrefer: boolean;
	canSeeLanguage: boolean;
	canSeeOddsType: boolean;
	crossSelling: boolean;
	disHomePrefer: string;
	disHomePreferID: number;
	disLanID: string;
	disLanguage: string;
	disOddsType: string;
	disOddsTypeKey: string
	langList: LangInfo[];
	oddsTypeList: OddsTypeList[];
	openDarkMode: boolean;
}
interface BetInfo {
	betAcceptBetter: boolean;
	betDefaultStake: string;
	betOddsSort: string;
	betOddsSortID: number;
	betQ1: string;
	betQ2: string;
	betQ3: string;
	betQBet: number;
	betStakeID: string;
	oddsSortList: OddsSortList[];
	betStakeValue: number;
	betStakeList: { Value: string, Text: string }[];
}

export interface GetMySettingsResponse {
	Data: AccountSettingsData | undefined;
}

export interface AccountSettingsData {
	displayInfo: DisplayInfo;
	betInfo: BetInfo;
}
// 用來渲染畫面
export type UpdateSettingData = {
	DefaultLanguage?: string;
	DisplayLanguage?: string;
	OddsTypeID?: string;
	OddsType?: string;
	BetStake?: number; // 0 betInfo.betStakeValue > 0 ? 1 :0
	BetterOdds?: boolean;
	eventSort?: string; // event sorting name 
	oddssort?: number
	inpbetStake?: number
	MobileOthersType?: string;
	quickBet?: number;
	betQ1?: string;
	betQ2?: string;
	betQ3?: string;
	enableCrossSelling?: number;
	darkMode?: number;
	isSmartLeagueSort?: number;
	isSmartSportSort?: number;
	mobileVersion?: number;
	sportsQuickBetStake?: number;
	BettingMode?: number;
};
interface SaveUserProfileResData {
	ErrorCode: number;
	ErrorMsg: string;
	Serial: null | number;
	Data: {
		TicketNo: number;
		Code: number;
		Message: string;
	};
}

export type QuickStakeInputType = {
	betQ3?: string;
	betQ2?: string;
	betQ1?: string;
};

export const getAccountSetting = async (): Promise<ApiQueryBasePromise<GetMySettingsResponse>> => {
	const apiDomain = useSiteStore.getState().apiDomain;
	const authToken = getAuthToken();
	const { updateAuthToken }=getAuthActions();
	const response = await fetchComposer.postWithBearerBase(cloudUrlParser(`${apiDomain.ctcdDomain}/Setting/GetMySettingsJson`), { body: null })(authToken);
	if (!response.ok) {
		throw new Error(`${response.status}: ${response.statusText}`);
	}

	const token = getResponseJwtHeader(response);
	if (token) {
		updateAuthToken(token);
	}

	return {
		data: await response.json(),
		jwtToken: token
	};
};

export const useGetAccountSetting = () => {
	const { data: actStatus } = useAccountStatus();
	const { data, isLoading, isError, error, refetch } = useQuery({
		queryKey: [queryKeys.ACCOUNT_SETTINGS],
		queryFn: () => getAccountSetting(),
		staleTime: 1000 * 60 * 5,
		cacheTime: 1000 * 60 * 10,
		refetchOnWindowFocus: false,
		enabled: actStatus === 1 || actStatus === 2
	});

	return {
		JwtToken: data?.jwtToken,
		userSetting: data?.data.Data,
		isLoading,
		isError,
		error,
		refetchAccountSettings: refetch
	};
};

export const updateUserSetting = async (userSetting: UserSetting): Promise<ApiQueryBasePromise> => {
	const apiDomain = useSiteStore.getState().apiDomain;
	const authToken = getAuthToken();

	const request = {
		body: JSON.stringify({
			UserSetting: userSetting
		}),
		headers: {
			'Content-Type': 'application/json',
			'Accept': 'application/json'
		}
	};

	const response = await fetchComposer.postWithBearerBase(cloudUrlParser(`${apiDomain.ctcdDomain}/api/Customer/UpdateUserSetting`), request)(authToken);
	if (!response.ok) {
		throw new Error(`${response.status}: ${response.statusText}`);
	}
	return {
		data: null,
		jwtToken: getResponseJwtHeader(response)
	};
}

export const saveAccountSetting = async (settingData: UpdateSettingData): Promise<ApiQueryBasePromise<SaveUserProfileResData>> => {
	const apiDomain = useSiteStore.getState().apiDomain;
	const formData = getFormData(settingData);
	const authToken = getAuthToken();
	const response = await fetchComposer.postWithBearerBase(cloudUrlParser(`${apiDomain.ctcdDomain}/Setting/SaveUserProfile`), { body: formData })(authToken);
	if (!response.ok) {
		throw new Error(`${response.status}: ${response.statusText}`);
	}
	return {
		data: await response.json(),
		jwtToken: getResponseJwtHeader(response)
	};
}

const saveNickName = async (nickName: string, isUseNicknameVoucher: boolean): Promise<ApiQueryBasePromise<ApiSuccessResponse<string>>> => {
	const authToken = getAuthToken();
	const { ctcdDomain } = useSiteStore.getState().apiDomain;
	const response = await fetchComposer.getWithBearer(cloudUrlParser(`${ctcdDomain}/api/Customer/SaveNickName?nickName=${nickName}&isUseNicknameVoucher=${isUseNicknameVoucher}`))(authToken);
	if (!response.ok) {
		throw new Error(`${response.status}: ${response.statusText}`);
	}

	return {
		data: await response.json(),
		jwtToken: getResponseJwtHeader(response)
	};
};

export const useSaveNickName = (nickName: string, isUseNicknameVoucher: boolean) => {
	const { data, isLoading, isError, error, refetch } = useQuery({
		queryKey: [queryKeys.SAVE_NICK_NAME],
		queryFn: () => saveNickName(nickName, isUseNicknameVoucher),
		refetchOnWindowFocus: false,
		enabled: false
	});

	return {
		data: data?.data,
		isLoading,
		isError,
		error,
		saveNickName: refetch
	};
};